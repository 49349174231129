<template>
  <div
    class="bg-cover bg-position-center bg-no-repeat h-2/3 md:h-[500px]"
    :style="{ backgroundImage: heroBackgroundImage }"
  >
    <div class="flex flex-col justify-center items-start bg-cover bg-center pt-20 mx-auto max-w-screen-xl h-full">
      <h1 id="hero_title" class="px-4 md:px-4 text-7xl md:text-8xl leading-11 font-semibold">
        <div class="text-Tourscanner_blue-900 dark:text-Tourscanner_blue-300">
          {{ $t('Compare')
          }}<span id="accent-wrapper" class="ml-2">
            <br class="md:hidden" />
            <span id="accent" class="js-accent">{{ $t('guided tours') }}</span>
            <br class="md:hidden" />
          </span>
        </div>
        <div class="text-Tourscanner_blue-900 dark:text-Tourscanner_blue-300">{{ $t('from multiple websites') }}</div>
      </h1>
      <div class="md:w-3/4 w-full">
        <SearchBar class="px-2 md:px-4 mb-10 mt-10 md:w-5/6" />
      </div>
      <div class="mt-10 w-full flex justify-center overflow-hidden">
        <LogoScroll></LogoScroll>
      </div>
    </div>
  </div>
  <div class="mx-auto max-w-screen-xl md:px-4 mt-0">
    <Carousel
      v-if="homeData?.recently_viewed?.length > 0 ?? false"
      :title="$t('Recently viewed')"
      :itemType="'identity-B'"
      :items="data['recently_viewed']"
      :cardWidth="150"
      class="my-5 md:my-10"
      :lazyLoadImg="false"
    />
    <Carousel
      v-if="homeData?.closest_attractions?.length > 0 ?? false"
      :title="$t('Things to do near you')"
      :itemType="'identity-A'"
      :items="data?.closest_attractions ?? []"
      :cardWidth="150"
      class="my-5 md:my-10"
      :lazyLoadImg="false"
    />
    <Carousel
      v-if="homeData?.top_attractions?.length > 0 ?? false"
      :title="$t('Top attractions')"
      :itemType="'identity-A'"
      :items="data?.top_attractions ?? []"
      class="my-5 md:my-10"
      :cardWidth="150"
    />
    <Carousel
      v-if="homeData?.top_cities_list?.length > 0 ?? false"
      :title="$t('Top destinations')"
      :itemType="'poster'"
      :items="data['global_top_cities_list']"
      class="my-5 md:my-5"
      :cardWidth="150"
      :cardHeight="200"
    />

    <!-- City Carousel to select-->
    <div>
      <h2 v-if="homeData?.country_name && topCities?.length > 0" class="text-4xl ml-4 mb-5 md:mb-10 mt-5">
        {{ $t('Best places to visit in') }} {{ homeData.country_name }}
      </h2>
      <div class="relative" ref="scrollContainer">
        <button
          v-if="canScrollLeft"
          @click="scrollLeft"
          class="arrow-button absolute left-0 top-1/2 transform -translate-y-1/2 bg-white dark:bg-zinc-800 rounded-full p-2 shadow-md z-10"
          title="arrow-left"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
          </svg>
        </button>
        <div
          @scroll="checkScroll"
          ref="scrollContent"
          class="overflow-x-auto whitespace-nowrap py-0 md:py-4 px-0 md:px-2 scrollbar-hide"
        >
          <div
            v-if="topCities?.length > 0 ?? false"
            class="inline-block"
            v-for="(city, index) in topCities"
            :key="city.name_alias"
            @click="selectCity(index)"
          >
            <div
              :class="[
                'flex items-center mx-2 py-2 px-4 md:px-5 md:py-2 rounded-full cursor-pointer transition duration-300 dark:text-gray-300',
                selectedCity === index ? 'bg-blue-100 dark:bg-zinc-800' : 'hover:bg-gray-100 dark:hover:bg-zinc-800 ',
              ]"
            >
              <SmartImg
                :src="city.featured_image"
                :alt="city.name_alias"
                class="h-10 w-10 rounded-full object-cover mr-2"
                sizes="10px"
              />
              <span>{{ city.name_alias }}</span>
            </div>
          </div>
        </div>
        <button
          v-if="canScrollRight"
          @click="scrollRight"
          class="arrow-button absolute right-0 top-1/2 transform -translate-y-1/2 bg-white dark:bg-zinc-800 rounded-full p-2 shadow-md z-10"
          title="arrow-right"
        >
          <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
          </svg>
        </button>
      </div>
      <Carousel
        :itemType="'identity-A'"
        :items="topCities?.[selectedCity]?.identities"
        :cardWidth="150"
        :showButtons="false"
        :key="selectedCity"
      />
    </div>

    <section class="py-16 sm:py-10 my-10">
      <div class="inner-container">
        <h2 class="medium text-center text-6xl mb-5">{{ $t('What makes ') }}{{ $t('TourScanner different') }}</h2>
        <div class="flex-column md:flex mx-auto justify-center space-y-16 md:space-y-0">
          <div
            class="flex flex-col text-center items-center justify-between bg-abstract-white w-full md:w-1/3 p-2 md:p-6 rounded-md"
          >
            <img
              src="/img/icons/search_B.svg"
              class="w-24 mb-0 md:mb-10 dark:filter dark:invert dark:invert-1"
              style="font-size: 5rem"
            />
            <h4 class="medium text-center text-2xl font-semibold">{{ $t('Smart Booking') }}</h4>
            <p class="text-$font-size-big m-0 flex-1 max-h-[50px]">
              {{ $t('Search 30 top travel websites all at once.') }}
            </p>
          </div>
          <div
            class="flex flex-col text-center items-center justify-between bg-abstract-whitew-full md:w-1/3 p-6 rounded-md"
          >
            <img
              src="/img/icons/explore.svg"
              class="w-24 mb-0 md:mb-10 dark:filter dark:invert dark:invert-1"
              style="font-size: 5rem"
            />
            <h4 class="medium text-center text-2xl font-semibold">{{ $t('Unmatched Selection') }}</h4>
            <p class="text-$font-size-big m-0 flex-1 max-h-[50px]">
              {{ $t('Browse over 1 Million curated tours and activities worldwide.') }}
            </p>
          </div>
          <div
            class="flex flex-col text-center items-center justify-between bg-abstract-white w-full md:w-1/3 p-6 rounded-md"
          >
            <img
              src="/img/icons/data_exploration.svg"
              class="w-24 mb-0 md:mb-10 dark:filter dark:invert dark:invert-1"
              style="font-size: 5rem"
            />
            <h4 class="medium text-center font-semibold text-2xl">{{ $t('Unbeatable Prices') }}</h4>
            <p class="m-0 flex-1 max-h-[50px]">
              {{ $t('Compare offers and get the best deal.') }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section>
      <ArticlesSection v-if="articlesData" :cardVersion="1" :objects="articlesData" />
    </section>
    <div class="container mx-auto px-4 my-10">
      <section id="mobile-app" class="text-white rounded-lg flex flex-col items-center justify-center relative">
        <AppContainer />
      </section>
    </div>
    <div class="flex flex-col md:flex-row items-center justify-between p-4 bg-white dark:bg-transparent mt-32">
      <div class="my-4 md:mr-4 w-full md:w-3/4">
        <h2 class="text-2xl font-semibold text-zinc-900 dark:text-zinc-100">{{ $t('Join our newsletter') }}</h2>
        <p class="text-zinc-600 dark:text-zinc-400">
          {{ $t('We’ll send you a nice newsletter once per week. No spam.') }}
        </p>
      </div>
      <div class="w-full md:flex-grow">
        <SubscribeForm />
      </div>
    </div>

    <!-- We don't use nuxt link locale because the blog articles have a different slug processing -->
    <div class="p-2">
      <div v-if="data?.top_links.length > 0" class="bg-gray-100 dark:bg-zinc-800 p-6 md:p-4 rounded-md">
        <div class="max-w-7xl mx-auto px-0 md:px-4">
          <h2>{{ $t('Explore More') }}</h2>
          <div class="flex flex-col justify-between items-start">
            <div v-for="section of data?.top_links" :key="section.title">
              <div v-show="section.links.length > 0">
                <h3 class="text-2xl font-semibold mt-4 md:mt-4 mb-1 md:mb-1">{{ section.title }}</h3>
                <div class="flex flex-wrap">
                  <div v-for="link of section.links" :key="link.url" class="mb-2">
                    <NuxtLink :to="link.url" class="text-gray-600 dark:text-gray-300 mr-5 mb-4 underline">{{
                      link.title
                    }}</NuxtLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="js">
import { onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import SubscribeForm from '~/components/LandingEmailSubscribe/SubscribeForm.vue';
import { handleApiResponse } from '~/server/utils/utils';

const { t, locale } = useI18n();
const route = useRoute();
let heroBackgroundImage = ref(null);

const scrollContainer = ref(null);
const scrollContent = ref(null);
const canScrollLeft = ref(false);
const canScrollRight = ref(false);
//Fetch articles
const articlesData = ref();
const topCities = ref([]);
const homeData = ref();

useHead({
  title: t('TourScanner - Compare Tours, Attractions & Travel Activities'),
  meta: [
    { name: 'title', content: t('TourScanner - Compare Tours, Attractions & Travel Activities') },
    { name: 'description', content: t('Compare hundreds of websites and book at the cheapest price. Save up to 70% on skip-the-line tickets for attractions, guided visits, free tours, excursions, outdoor activities, day trips, things to do, sports & experiences.') },
    { name: 'keywords', content: t('tours, attractions, tickets, best price, cheap, compare, things to do, travel activities') },
    { name: 'robots', content: 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1' }
  ],
});

const rule = useRobotsRule();
rule.value = 'index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1';

useSeoMeta({
  ogType: 'website',
  title: t('TourScanner - Compare Tours, Attractions & Travel Activities'),
  ogTitle: t('TourScanner - Compare Tours, Attractions & Travel Activities'),
  description: t('Compare hundreds of websites and book at the cheapest price. Save up to 70% on skip-the-line tickets for attractions, guided visits, free tours, excursions, outdoor activities, day trips, things to do, sports & experiences.'),
  ogDescription: t('Compare hundreds of websites and book at the cheapest price. Save up to 70% on skip-the-line tickets for attractions, guided visits, free tours, excursions, outdoor activities, day trips, things to do, sports & experiences.'),
  keywords: t('tours, attractions, tickets, best price, cheap, compare, things to do, travel activities'),
  ogUrl: `https://www.tourscanner.com/`,
  twitterCard: 'summary_large_image',
});

const heros = [
t('attractions'),
t('excursions'),
t('outdoor activities'),
t('tickets'),
t('guided tours'),
];

let heroIndex = 0;
let accents = [];

const switchText = () => {
  const newText = heros[heroIndex];
  accents.forEach(accent => {
    accent.innerHTML = newText;
  });
  heroIndex++;
  if (heroIndex >= heros.length) {
    heroIndex = 0;
  }
};

onMounted(() => {
  updateBackgroundImage();
  if (typeof document !== 'undefined') {
    accents = document.querySelectorAll('.js-accent');
    accents.forEach(accent => {
      accent.style.animationPlayState = 'running';
      accent.addEventListener('animationiteration', switchText);
    });
  }
  selectCity(0);
  checkScroll();
  fetchBlogPosts();
  fetchCities();
});

let logIP = false;
try {
  logIP = route.query.logIP
} catch (error) {
  console.error('Error fetching logIP:', error);
}


const { data, error } = await handleApiResponse(`/api/home`, {
  params: {
    key: 'home',
    lang: locale.value.split('-')[0],
    // RVProducts: useCookie('RVProducts').value,
    // RVDestinations: useCookie('RVDestinations').value,
    type: 'home',
    logIP: logIP,
    lazy: true,
    server: false,
  }
});
if (data) {
  homeData.value = data.value;
}

const selectedCity = ref(null);

const selectCity = (index) => {
  selectedCity.value = index;
};

function checkScroll() {
  if (scrollContent.value) {
    canScrollLeft.value = scrollContent.value.scrollLeft > 0;
    canScrollRight.value = scrollContent.value.scrollLeft < scrollContent.value.scrollWidth - scrollContent.value.clientWidth;
  }
}

function scrollLeft() {
  if (scrollContent.value) {
    scrollContent.value.scrollBy({ left: -200, behavior: 'smooth' });
  }
}

function scrollRight() {
  if (scrollContent.value) {
    scrollContent.value.scrollBy({ left: 200, behavior: 'smooth' });
  }
}


const colorMode = useColorMode();
const isDark = computed(() => colorMode.value === 'dark');
// Function to update the background image based on the current mode
const updateBackgroundImage = () => {
  heroBackgroundImage.value = `url('/img/hero_${isDark.value ? 'darkmode' : 'lightmode'}.svg')`;
};

// Watch the `isDark` computed property to react to changes in the dark mode
watch(isDark, () => {
  updateBackgroundImage();
});

async function fetchCities() {
  const data = await $fetch(`/api/home`, {
    params: {
      key: 'top-cities',
      lang: locale.value.split('-')[0],
      lazy: true,
      server: false,
      type: 'top-cities',
    },
  });
  topCities.value = data;
}

async function fetchBlogPosts() {
  try {
    const { data, error } = await useFetch(`/api/home`, {
      params: {
        key: 'blog-posts',
        lang: locale.value.split('-')[0],
        lazy: true,
        server: false,
        type: 'blog-posts',
      },
    });
    if (data.value) {
      articlesData.value = data.value;
    }
  } catch (error) {
    console.error('Error fetching blog posts:', error);
  }
}
</script>

<style scoped>
.no-scrollbar::-webkit-scrollbar {
  display: none;
}
.no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#tourscanner-different {
  background-color: #fafbff;
}

#accent {
  color: #008ec8;
  position: absolute;
  animation-name: accent-anim;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-delay: -1.55ms;
  animation-play-state: paused;
}

@keyframes accent-anim {
  0% {
    transform: translateY(100%);
    opacity: 0;
    animation-timing-function: ease-out;
  }
  12% {
    transform: translateY(0%);
    opacity: 1;
  }
  88% {
    transform: translateY(0%);
    opacity: 1;
    animation-timing-function: ease-in;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
}

/* Hide the arrows when the user has a touchscreen */
.arrow-button {
  display: none;
}

@media (hover: hover) {
  .arrow-button {
    display: block;
  }
}
</style>
